<template>
  <nav class="sticky top-0 z-50 border-y border-gray-200 dark:border-gray-600 bg-idle-white dark:bg-gray-800">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
      <div class="flex items-center">

        <NuxtLink to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <NuxtImg src="/images/logos/toolstation-logo-halo.svg" width="164" alt="Toolstation Logo" loading="lazy"/>
        </NuxtLink>
      </div>
      <button data-collapse-toggle="navbar-default" type="button"
              class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden
              hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul class="font-medium flex flex-col items-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg
        md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:border-gray-700">
          <li>
            <NuxtLink to="/ui/"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
                      >
              Home
            </NuxtLink>
          </li>

          <li>
            <NuxtLink to="/ui/guides/introduction"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
              Guide
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/ui/atoms"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
              Atoms
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/ui/molecules"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
            >
              Molecules
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/ui/organisms"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
            >
              Organisms
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/ui/templates"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
            >
              Templates
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/ui/pages"
                      class="text-sm block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      exact-active-class="!text-primary dark:!text-primary"
                      active-class="!text-primary dark:!text-primary"
            >
              Pages
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>


</template>
<script setup lang="ts">

</script>
